import React from "react";
import { IntroSecDetails } from "../config/dataConfig";

const Logo = () => {
    return <>
        <span className="px-2">{IntroSecDetails.logoText}</span>
        <span className="px-2">|</span>
        <span className="px-1 text-1 d-sm-none">{IntroSecDetails.logoSubText}</span>
        <span className="px-1 text-1 d-none d-sm-block">{IntroSecDetails.logoSubTextDesktop}</span>
        {/* <div className="ogImageMaker text-center">
            <span className="px-2 text-19">{IntroSecDetails.logoText}</span>
            <br />
            <span className="px-2 text-2">Fractional Engineering Services</span>
        </div> */}
    </>;
}

export default Logo;