export const IntroSecDetails = {
  typeSpeed: 120,
  deleteSpeed: 99999999,
  heading1: "Hi, I'm a fractional",
  typeWritterHeadings: [
    "Engineering Leader",
    // "Manager",
    // "CTO",
  ],
  heading2: "with 18+ years of experience based in Sao Paulo, Brazil",
  btnText: "Reach out to me on LinkedIn",
  btnLink: "https://www.linkedin.com/in/anrfreitas/?locale=en_US",
  logoText: "Andy",
  logoSubText: "Fractional Eng. Leader for Startups",
  logoSubTextDesktop: "Fractional Engineering Leader for Startups",
};
export const AboutSecDetails = {
  image: {
    img: "images/about-me.jpg",
  },
  information: {
    title1: "About Me",
    name: "André Freitas",
    nickName: "Andy",
    desc1:
      "Experienced and forward-thinking Engineering Leader with over 18 years of experience in the software engineering field in which 6 plus years of extensive experience leading cross-functional teams and driving technological advancement in various industries.",
    desc2:
      "Passionate about fostering a culture of collaboration and continuous learning, always empowering teams to exceed expectations and deliver exceptional results. Additionally, I'm dedicated to push my teams to tackle challenges with innovation and agility.",
  },
  moreAbout: [
    {
      num: 18,
      title: "Years Experience",
    },
    {
      num: 120,
      title: "Mentored Direct Reports",
    },
    {
      num: 14,
      title: "Happy Clients",
    },
  ],
};

export const ServicesDetails = {
  heading: {
    title: "My Services",
    text: "Unlock the potential of your next project with my expertise! Many clients have procured exceptional results while working with Me. Let's take your project to new heights!",
  },
  details: [
    {
      name: "Team Leadership",
      desc: "Mentors and manages technical teams, aiding in recruitment and fostering a skilled technical workforce.",
      icon: "fas fa-bullhorn",
    },
    {
      name: "Digital Transformation",
      desc: "Drives digital transformation by integrating modern tech for enhanced business operations.",
      icon: "fas fa-palette",
    },
    {
      name: "Infrastructure Management",
      desc: "Oversees the setup and maintenance of IT infrastructure ensuring robust and reliable IT operations.",
      icon: "fas fa-server",
    },
    {
      name: "Crisis Management",
      desc: "Provides leadership during technical crises by implementing solutions to critical problems efficiently.",
      icon: "fas fa-snowflake",
    },
    {
      name: "Performance Metrics",
      desc: "Establishes OKRs and KPIs for technology initiatives in order to provide clear insights to stakeholders.",
      icon: "fas fa-chart-line",
    },
    {
      name: "Cost Management",
      desc: "Efficiently manages the technology budget, identifying cost-saving opportunities while ensuring quality.",
      icon: "fas fa-wallet",
    },
  ],
};

export const MyWorking_Skills = {
  heading: {
    title: "Why Am I Different?",
    text: "I quickly adapt to changes in technology trends, market conditions, and business priorities by driving technological innovation and align technology strategies with business goals.",
  },
  video: {
    vdo: "http://www.youtube.com/watch?v=7e90gBu4pas",
  },
  working: [
    {
      name: "Technical Expertise",
      icon: "fas fa-fingerprint",
      desc: "Proficiency in software development, systems architecture, and infrastructure.",
    },
    {
      name: "Strategic Vision",
      icon: "fas fa-crosshairs",
      desc: "Seeks opportunities for innovation and growth through technology.",
    },
    {
      name: "Leadership Skills",
      icon: "fas fa-people-carry",
      desc: "Experience in leading, managing and transforming technology teams.",
    },
    {
      name: "Communication Skills",
      icon: "fas fa-microphone-alt",
      desc: "Encourages a culture of transparency, fostering trust and accountability.",
    },
    {
      name: "Innovative Thinking",
      icon: "fas fa-cubes",
      desc: "Promotes a culture of innovation and creativity thinking in problem-solving.",
    },
    {
      name: "Adaptability and Agility",
      icon: "fas fa-bolt",
      desc: "Encourages a culture of adaptability to respond to unexpected challenges.",
    },
  ],
  skills: [
    {
      skill: "Team Leadership",
      percent: 95,
    },
    {
      skill: "Software Development",
      percent: 95,
    },
    {
      skill: "Project Management",
      percent: 90,
    },
    {
      skill: "Risk Management",
      percent: 85,
    },
  ],
};

export const CallToActionDetails = {
  heading: "Let's start a partnership today!",
  subHeading: "I am open to work on your business. I'm sure I can help you with my skills.",
  btnText: "Hire Me",
};

export const WorkProcessDetails = {
  heading: {
    title: "Hiring Process",
    text: "Delivering work within time and budget which meets client’s requirements is my moto. I will help you to achieve your goals and to grow your business as well as setting up a high performance culture within your organization.",
  },
  WDetails: [
    {
      sno: 1,
      name: "Introduction",
      desc: "We initiate contact to introduce our services and discuss how we can support your business and engineering project needs.",
    },
    {
      sno: 2,
      name: "Understanding your needs",
      desc: "We dive deep into understanding your specific engineering requirements, projects and business objectives.",
    },
    {
      sno: 3,
      name: "Defining scope of work",
      desc: "We outline the detailed scope of work, ensuring business, project aspects and deliverables are clearly defined.",
    },
    {
      sno: 4,
      name: "Agree on availability",
      desc: "We engage in detailed discussions to align on my availability for your business, ensuring seamless project support.",
    },
    {
      sno: 5,
      name: "Final discussion",
      desc: "We conduct a final discussion to address any outstanding questions and finalize all project details thoroughly.",
    },
    {
      sno: 6,
      name: "Contract sign off",
      desc: "We complete the process by signing the contract, formalizing our partnership and project commencement.",
    },
  ],
};

export const OurClientsDetails = {
  heading: {
    title: "My Clients",
    text: "See what my happy clients say about me. I'm proud to have my clients.",
  },
  clients: [
    {
      name: "Jay Shah",
      img: "images/testimonial/client-sm-1.jpg",
      exp: "Founder at Icomatic Pvt Ltd",
      summary:
        " Easy to use, reasonably priced simply dummy text of theprinting and typesetting industry. Quidam lisque persiusinteresset his et, in quot quidam possim iriure. simply dummy text of the printing and typesetting industry.",
    },
    {
      name: "Patric Cary",
      img: "images/testimonial/client-sm-2.jpg",
      exp: "Freelancer from USA",
      summary:
        " “I am happy Working with printing and typesetting industry.Quidam lisque persius interesset his et, in quot quidampersequeris essent possim iriure. simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Dennis Jacques",
      img: "images/testimonial/client-sm-3.jpg",
      exp: "iDeal Inc",
      summary:
        " “Only trying it out since a few days. But up to now excellent.Seems to work flawlessly. priced simply dummy text of theprinting and typesetting industry.”",
    },
    {
      name: "Chris Tom",
      img: "images/testimonial/client-sm-4.jpg",
      exp: "Company CEO from UK",
      summary:
        " “I have used them twice now. Good rates, very efficientservice and priced simply dummy text of the printing andtypesetting industry quidam interesset his et. simply dummy text of the printing and typesetting industry. Excellent.”",
    },
  ],
  posters: [
  //   {
  //     id: 1,
  //     img: "images/clients/client-logo2.png",
  //   },
  //   {
  //     id: 2,
  //     img: "images/clients/client-logo3.png",
  //   },
  //   {
  //     id: 3,
  //     img: "images/clients/client-logo1.png",
  //   },
  //   {
  //     id: 4,
  //     img: "images/clients/client-logo2.png",
  //   },
  //   {
  //     id: 5,
  //     img: "images/clients/client-logo3.png",
  //   },
  //   {
  //     id: 6,
  //     img: "images/clients/client-logo1.png",
  //   },
  //   {
  //     id: 7,
  //     img: "images/clients/client-logo3.png",
  //   },
  //   {
  //     id: 8,
  //     img: "images/clients/client-logo1.png",
  //   },
  //   {
  //     id: 9,
  //     img: "images/clients/client-logo2.png",
  //   },
  //   {
  //     id: 10,
  //     img: "images/clients/client-logo3.png",
  //   },
  //   {
  //     id: 11,
  //     img: "images/clients/client-logo1.png",
  //   },
  //   {
  //     id: 12,
  //     img: "images/clients/client-logo2.png",
  //   },
  ],
};

export const ContactDetails = [
  {
    icon: "fas fa-map-marker-alt",
    title: "PO Box",
    p1: "1209 Coffeen Ave.",
    p2: " Suite 1200, Sheridan WY 82801",
  },
  {
    icon: "fas fa-phone-alt",
    title: "Call Me Now",
    p1: "Phone: +1 (307) 275-8030",
    p2: " Whatsapp: +1 (307) 275-8030",
  },
  {
    icon: "fas fa-envelope",
    title: "Inquiries",
    p1: "info@andyconsulting.co",
    p2: " Mon to Fri (9 am – 6 pm) MT",
  },
];

export const socialIcons = [
  // {
  //   title: "Twitter",
  //   icon: "fab fa-twitter",
  //   href: "https://twitter.com/harnishdesign/",
  //   className: "social-icons-twitter",
  // },
  // {
  //   title: "Facebook",
  //   icon: "fab fa-facebook-f",
  //   href: "http://www.facebook.com/harnishdesign/",
  //   className: "social-icons-facebook",
  // },
  {
    title: "Linkedin",
    icon: "fab fa-linkedin-in",
    href: "https://www.linkedin.com/in/anrfreitas/?locale=en_US",
    className: "social-icons-linkedin",
  },
  // {
  //   title: "Email",
  //   icon: "fas fa-envelope-open-text",
  //   href: "mailto:andy@sharpcomputing.io",
  //   className: "social-icons-email",
  // },
  {
    title: "WhatsaApp",
    icon: "fab fa-whatsapp",
    href: "https://wa.me/13072758030",
    className: "social-icons-whatsapp",
  },
  // {
  //   title: "Dribbble",
  //   icon: "fab fa-dribbble",
  //   href: "http://www.dribbble.com/harnishdesign/",
  //   className: "social-icons-dribbble",
  // },
  // {
  //   title: "GitHub",
  //   icon: "fab fa-github",
  //   href: "http://www.github.com/",
  //   className: "social-icons-github",
  // },
];